import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import { Container, Row, Col } from 'reactstrap';

import SubscriptionForm from '../components/SubscriptionForm';

import Leftblock from '../components/Leftblock';
import Rightblock from '../components/Rightblock';

import moment from 'moment';
import { graphql } from 'gatsby';
import ScrollingEff from '../components/ScrollingEff';
class LetsBeFwends extends React.Component {

  

  render() {
    let campaignNodes;

    if (this.props.data.allMarkdownRemark.edges) {
      let i = -1;
      campaignNodes = this.props.data.allMarkdownRemark.edges.map(edge => {
        let node = edge.node;
        let title = node.frontmatter.title;
        let date = node.frontmatter.date;
        let link = node.frontmatter.path;
        let image = node.frontmatter.featureImage;
        let lead = node.frontmatter.abstract;

        let now = moment();
        if (now.isSameOrAfter(moment(date))) {
          i++;
          if (i % 2 === 0) {
            return (
              <Leftblock
                headline={title}
                date={date}
                link={link}
                lead={lead}
                image_src={image}
                image_alt={title}
                key={title}
                >
              </Leftblock>
              )
          } else {
            return (
              <Rightblock
                headline={title}
                date={date}
                link={link}
                lead={lead}
                image_src={image}
                image_alt={title}
                key={title} 
                >
            </Rightblock>
            )
          }
        } else {
          return <></>
        }

        
        

        
    });
  }
    return (
      <Layout>
      <SEO title="Let's be Fwends" description="A journal of Digitality."/>
      <ScrollingEff />
      <div>
      <Container fluid className="w-80">
        <Row>
          <Col className="newsletteroverview">
            <h1>Let's be Fwends - The Archive</h1>
            <div className="newsletter-intro">
            <p className="lead">Get a bunch of links into your inbox every other week.</p>
                <p>Let's be Fwends is a journal about agility, organisations, technology, and the larger media landscape. And most importantly the role of all of us in all of that.</p>                
            </div>

            <SubscriptionForm />

            <h2>Previous Issues</h2>


            </Col>
          </Row>
          </Container>
          {campaignNodes}
          </div>
      </Layout>
    )
  }

};

export default LetsBeFwends;
export const pageQuery = graphql`
    query {
allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, filter: {frontmatter: {path: {regex: "/lets-be-fwends/"}, date: {}, lang: {}}}) {
  edges {
    node {
      id
      excerpt(pruneLength: 250)
      html
      frontmatter {
        date
        path
        featureImage
        title
        abstract
      }
    }
  }
}}`