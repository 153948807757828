import React from 'react';

import { Container, Row, Col } from 'reactstrap';

import { formatDate } from '../utils/dates';
import Link from '../net/Link';
/**
 * Leftblock shows its image on the left side and is indented four columns from the right in mobile.
 * (Which means that each column is simply 8 columns wide, so we force a break between the two) 
 * 
 * @class Leftblock
 * @extends {React.Component}
 */
class Leftblock extends React.Component {
  render() {
    let date = formatDate(this.props.date);

    let dateblock = '<span class="date">' + date + '</span>';
    let h_block = this.props.headline + dateblock;
    return (
      <Container fluid={true} className="leftblock sideblock block w-80">
        <Row className="align-items-center">
          <Col sm="8" md={{size: 5, offset: 1}} className="left">
            <Link to={this.props.link}>
              <img src={this.props.image_src} className="img-fluid" alt={this.props.image_alt}/>
            </Link>
          </Col>
          <Col sm="8" md={{size:5, offset: 0}} className="right">
          {this.props.children}
            <Link to={this.props.link}>
              <h1 dangerouslySetInnerHTML={{__html: h_block}} />
              <p className="lead d-sm-none d-lg-block" dangerouslySetInnerHTML={{__html:this.props.lead}}/>
              <p><span className="readmore">Read more</span></p>
            </Link>
          </Col>
        </Row>
      </Container>
    )
  }
}

Leftblock.defaultProps = {
  headline: '',
  date: '2017-11-20T07:00:00+00:00',
  lead: '',
  image_src: '',
  image_alt: '',
  link: '/'
}

export default Leftblock;