import React from 'react';

import { Container, Row, Col } from 'reactstrap';

import Link from '../net/Link';

import { formatDate } from '../utils/dates';

/**
 * Rightblock shows its image on the right, and indents two columns from the left in responsive.
 * 
 * Since the reponsive logic is still that the text is below the image, we need to re-order the two
 * columns on the mobile breakpoint.
 * 
 * @class Rightblock
 * @extends {React.Component}
 * 
 */
class Rightblock extends React.Component {

  render() {
    let date = formatDate(this.props.date);
    let dateblock = '<span class="date">' + date + '</span>';
    let h_block = this.props.headline + dateblock;

    return (
      <Container fluid={true} className="rightblock sideblock block w-80">
        <Row className="align-items-center">
          <Col sm={{size:8, offset:4, order:1}} md={{size:5, offset:1, order:2}} className="order-sm-2 order-md-1 left">
          {this.props.children}
          <Link to={this.props.link}>
            <h1 dangerouslySetInnerHTML={{__html: h_block}}/>
            <div className="lead" dangerouslySetInnerHTML={{__html:this.props.lead}}/>
            <p><span className="readmore">Read more</span></p>
          </Link>
          </Col>
          <Col sm={{size:8, offset:4}} md={{size:5, offset:0, order:2}} className="order-sm-1 order-md-2 right">
            <Link to={this.props.link}>
              <img src={this.props.image_src} className="img-fluid" alt={this.props.image_alt}/>
            </Link>
          </Col>

        </Row>
      </Container>
    )
  }
}

Rightblock.defaultProps = {
  headline: '',
  date: '2017-11-20T07:00:00+00:00',
  lead: '',
  image_src: '',
  image_alt: '',
  link: '/'
}

export default Rightblock;